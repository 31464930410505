

html.creator.crew
  body .body .crew
    position relative
    width 100%
    min-height 100vh

    .crew-header
      position fixed
      top 0
      right 0
      left 0
      height 4.8rem
      z-index 1
      @import "crew/crew.header.styl"

    .crew-menu_nav
      width 16.6rem
      position fixed
      top 4.8rem
      bottom 0
      left 0
      z-index 1
      overflow-y auto
      @import "crew/crew.menu_nav.styl"

    .crew-body
      position absolute
      top 0
      right 0
      left 16.6rem
      display inline-flex
      min-height 100%
      box-sizing border-box
      margin-top 4.8rem
      padding 40px
      background-color #fff

      .crew-active_store_list
        @import "crew/store_management/list/active_list/index.styl"
      .crew-store_editing
        @import "crew/store_management/detail/index.styl"
      .crew-manager_management
        @import "crew/crew.manager_management.styl"
      .crew-store_withdraw
        @import "crew/crew.store_withdraw.styl"
      .crew-crew_withdraw
        @import "crew/crew.crew_withdraw.styl"
      .crew-crew_bank_account
        @import "crew/crew.bank_account.styl"
      .crew-crew_adjustment
        @import "crew/crew.adjustment.styl"
      .crew-store_profit
        @import "crew/crew.store_profit.styl"
      .crew-confirmation
        @import "crew/crew.confirmation.styl"
      .crew-product_list
        @import "crew/crew.product_list.styl"
      .crew-product_check
        @import "crew/crew.product_check.styl"
      .crew-inspect
        @import "crew/crew.inspect.styl"
      .crew-inactive_seller_list
        @import "crew/store_management/list/inactive_list/index.styl"
      .crew-disapproval_store_list
        @import "crew/store_management/list/disapproval_list/index.styl"
      .crew-unregistered_store_list
        @import "crew/store_management/list/unregistered_list/index.styl"
      .crew-rejected_store_list
        @import "crew/store_management/list/rejected_list/index.styl"
      .crew-invited_store_list
        @import "crew/store_management/list/invited_list/index.styl"
      .crew-events
        @import "crew/crew.event_list.styl"
      .crew-events-sort
        @import "crew/crew.event_sort.styl"
      .crew-solution
        @import "crew/crew.solution.styl"
  .crew_level_actions
    > .don_wrapper
      > .header
        height 56px
        display flex
        align-items center
        justify-content center
        border-bottom 1px solid #dfdfdf
      > .body
        padding-top 50px

    .wrapper
      width 70%
      margin auto
      .table-wrapper
        margin-top 40px
      .chart-wrapper
        margin-bottom 30px

  .level-action-table
    .category
      background-color rgba(24,28,33,0.025)
      font-weight bold

  .crew-level-update-page
    @import "crew/crew.manager_level_update.styl"

  .crew-withdraw-approve-frame
    @import "crew/crew.store_withdraw_approve.styl"

html.fixed, body.fixed
  overflow-y visible !important

@import "crew/crew.chart.styl"
@import "crew/store_management/seller_registration_popup/index.styl"
@import "crew/store_management/store_name_edit_popup/index.styl"
@import "crew/crew.store_management.store_creation.styl"
@import "crew/crew.manager_management.manager_registration.styl"
@import "crew/crew.banner.styl"
@import "crew/crew.banner.editor.styl"
@import "crew/crew.common.styl"
@import "./plus/plus.crew.common.styl"

@import "crew/crew.feed_list.styl" // seller tip
@import "crew/crew.feed_detail.styl" // seller tip detail
@import "crew/crew.today_detail.styl" // 컨텐츠, 쇼케이스 디테일
@import "crew/crew.nav_tab.styl"
@import "crew/crew.archives.styl"

@import "../../../modules/Plus/PopupStores/Detail/F/Style/Pc/index.styl"

