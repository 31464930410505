&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  h2
    flex-shrink 0
    width 100%
    height 4rem
    margin 0
    display flex
    align-items center

  .body
    width 100%
    .filter_container
      margin 30px 0
      button[name="date"], button[name="approve"]
        display inline-flex
        float right
        align-items center
        i
          margin-right 8px

    .chart-wrapper
      width 100%

  #withdraws
    .feature
    .status
      text-align center
      padding 0

.pending-container
  margin-top 30px
  .pending-list
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3개 칼럼 */
    border 1px solid #FFD950
    border-radius 20px
    width 30%
    min-width 500px
  .pending-withdraw-item
    margin 10px 0
    text-align center
  .pending-withdraw
    text-decoration underline
    border 0
    background-color transparent
