.mp-shop-product-item
  margin 0 20px 40px 0
  display inline-block
  vertical-align top
  text-align center
  position relative
  &.sortable_drag_ghost
  &.drag_ghost
    visibility hidden
  &.sortable_drag_on
  &.drag_on
    .mp-shop-product-store,.mp-shop-product-name,.embed_option,.mp-shop-product-price,.mp-shop-product-limited
      display none
  &[data-product-type="plus"]
    width 304px
  &[data-product-type="store"]
    width 265px
  &[data-product-type="crew-mall"]
    width 305px
  &[data-product-type="event"]
    width calc(25% - 18px)
    margin 0 9px 18px
    &:last-child
      margin-right auto
    .embed_option
      position absolute
      top 0
      right 0
      width 40px
      z-index 1
      cursor pointer
      background-color transparent
      padding 0
      border 0
      &:focus
        outline none
      img
        width 40px
      .options
        display none
        position absolute
        right 0
        border-radius 2px
        box-shadow 0 1px 2px 0 rgba(0, 0, 0, 0.3)
        border solid 1px #e5e5e5
        background-color #ffffff
        .option
          padding 13px 29px
          white-space nowrap
          &:hover
            background-color #3c89f9
            border-radius 2px
            color white
      &.opened
        .options
          display block
    .mp-shop-product-name
      font-size 14px
    .mp-shop-product-image
      margin-bottom 10px
  &[data-product-type="setting"]
    width 100%
  &[data-product-type="arrangement"]
    width 100%
    margin 0
    user-select none
    .mp-shop-product-status
      display none !important
  &[data-product-type="crew-check"]
    width 100%
    display flex
    flex-direction column
    .mp-shop-product-store,.mp-shop-product-name
      order 1
      margin-bottom 8px
    .mp-shop-product-images
      order 2
      margin-bottom 0
  &[data-product-type="crew-admin"]
    width 33.333333%
    margin 0 0 75px
    cursor pointer
    padding 0
    color #000
  &[data-product-type="crew-admin"]
  &[data-product-type="crew-check"]
    .mp-shop-product-images
      position relative
      width 100%
      margin-bottom 20px
      .mp-shop-product-image
        display inline-block
        position relative
        width 50%
        margin 0
        img
          max-width 96%
          max-height 100%
      &[data-flex-wrap="true"]
        flex-wrap wrap
        .mp-shop-product-image
          width 33.33333%

  .mp-shop-product-image
    position relative
    font-size 0
    margin-bottom 20px
  .mp-shop-product-status-tooltip
    display none
  .mp-shop-product-tag
  .mp-shop-product-status
    position absolute
    top 10px
    left auto
    right 10px
    display flex
    gap 4px
  .mp-shop-product-status__item
    color #fff
    padding 5px 8px 4px
    border-radius ptr(4)
    font-size 12px
    line-height 1
    letter-spacing -0.5px
    display flex
    align-items center
    background-color #fff
    position relative
    &:hover
      .mp-shop-product-status-tooltip
        z-index 1
        position absolute
        width fit-content
        left -1000px
        right -1000px
        margin 0 auto
        top 26px
        display block
        font-size 13px
        line-height 1.38
        letter-spacing -0.5px
        color #fff
        padding 10px
        border-radius 6px
        box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
        background-color rgba(17, 17, 17, 0.9)
        white-space nowrap
        svg
          position absolute
          top -5px
    &:before
      content ''
      display block
      background-repeat no-repeat
      background-size 14px 14px
      width 14px
      height 14px
      margin -1px 4px 0 0
    &[data-status="public"]
      box-shadow 0 0 0 1px #5eda42 inset
      color #5eda42
      &:before
        background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/0cdf2841d33902b37040f20e3bcc57cadb2968441.png)
    &[data-status="private"]
      box-shadow 0 0 0 1px #b2b2b2 inset
      color #b2b2b2
      &:before
        background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/c4278bcd4c48c7b216f7af22a13e3a7416f71bf31.png)
    &[data-status="ready_confirm"]
    &[data-status="not_use_product"]
    &[data-status="prohibited"]
    &[data-status="not_confirmed"]
      box-shadow 0 0 0 1px #ff8970 inset
      color #f86041
      &:before
        background-image url(//s3.marpple.co/files/u_29089/2020/11/original/cebbdc422aa6a293f8c327f16cf1132f1e5c1fec1.png)
    &[data-status="checking"]
      box-shadow 0 0 0 1px #3c89f9 inset
      color #3c89f9
      &:before
        background-image url(//s3.marpple.co/files/u_1093672/2021/3/original/fbd009d20a6e7e9fab8e067f4d7cab53569d15dd1.png)
    &[data-status="out_of_stock"]
      box-shadow 0 0 0 1px #6b6b6b inset
      color #6b6b6b
      &:before
        background-image url(//s3.marpple.co/files/u_29089/2024/9/original/bcb58029d09b3ffbc6c500481feecce81b36c56a1.svg)
        background-size 14px


    &.related-excel__tooltip
      position absolute
      width 182px
      margin 0 auto
      left -100px
      right -100px
      bottom -50px
      display block
      font-size 13px
      line-height 1.38
      letter-spacing -0.5px
      color #fff
      padding 10px
      border-radius 6px
      box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
      background-color rgba(17, 17, 17, 0.9)
      z-index 3
      svg
        position absolute
        top -5px

  &[data-tag-id="false"]
    .mp-shop-product-tag
      display none
  &[data-tag-id="limited_edition"]
    .mp-shop-product-tag
      background-color #f66565
  &[data-tag-id="coming"]
    .mp-shop-product-tag
      background-color rgb(51, 203, 181)
  &[data-tag-id="sold_out"]
    .mp-product-img
    .mp-shop-product-name
    .mp-shop-product-middle
    .mp-shop-product-bottom
      opacity .6
    .mp-shop-product-tag
      background-color #000
  .mp-shop-product-name
    font-size 16px
    font-weight 600
    letter-spacing -0.5px
    //word-break keep-all
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    color #000
  .mp-shop-product-cate-item-name
    margin-top 6px
    font-size 13px
    letter-spacing -0.5px
    color #828282
  .mp-shop-product-store
    font-size 12px
    line-height 1.33
    letter-spacing -0.5px
    margin-top 4px
    //word-break keep-all
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    color $grey_500
    a
      color $grey_500

    &__btn-download--digital-product
      display inline-block
      height 39px
      margin 10px 0 20px
      background-color #3c89f9
      color white !important
      border none
      border-radius 2px
      padding 0 18px
      letter-spacing -0.5px
      & > .btn-download--digital-product--icon
        width 12px
        height 12px
        margin-right 5px
        vertical-align middle
  .mp-shop-product-price
    font-weight 600
    letter-spacing -0.5px
    margin-top 12px
  .mp-shop-product-limited
    display flex
    align-items center
    justify-content center
    font-size 12px
    letter-spacing -0.5px
    color #999999
    margin-top 20px
    &.hide
      display none
  .mp-shop-product-top
    &__pb-status
      font-size 12px
      color #fff
      font-weight 600
      position absolute
      bottom 0
      width 100%
      padding 10px 0
      line-height 1
      justify-content center
      align-items center
      display none
      &:before
        content ""
        margin-top -2px
        display block
        width 12px
        height 12px
        background-size 12px 12px
        margin-right 5px
      &[data-pb-status="NOPASS"]
        display flex
        background-color rgba(246, 101, 101, 0.85)
        &:before
          background-image url(//s3.marpple.co/files/u_29089/2022/11/original/16f64720d6473a8b5a24caeb62fa20c6c3928d681.webp)
      &[data-pb-status="PENDING"]
        display flex
        background-color rgba(17, 17, 17, 0.45)
        &:before
          background-image url(//s3.marpple.co/files/u_29089/2022/11/original/37a36da678d1280ca69e1ff001962ef43216c3a91.webp)
  @media screen and (min-width: 1025px)
    &:nth-child(4n)
      margin-right 0
    .mp-shop-product-store
      a
        &:hover
          text-decoration underline
  @media screen and (max-width: 1024px)
    &[data-product-type="plus"]
    &[data-product-type="store"]
    &[data-product-type="crew-mall"]
      width 50%
      margin 0 0 25px
    >a
      display block
      margin-right 5px
      .mp-shop-product-image
        margin-bottom 16px
        .mp-shop-product-tag
          font-size 10px
      .mp-shop-product-name
        font-size 14px
        letter-spacing -0.44px
      .mp-shop-product-price
        margin-top 12px
      .mp-shop-product-store
        a
          font-weight 400
      .mp-shop-product-limited
        margin-top 12px
    .mp-shop-product-top
      &__pb-status
        padding 8px 0

    &:nth-child(2n)
      >a
        margin-left 5px
        margin-right 0

span.mp-product-img-bg-wrap
  position relative
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  html.creator-settings &
    border-radius 6px
    overflow hidden
  &:after
    content ""
    display block
    padding-bottom 100%
  .mp-product-img
    object-fit contain
    width 100%
    position absolute
    top 0
    left 0
    bottom 0
    right 0
    margin auto
    max-width 100%
    max-height 100%
