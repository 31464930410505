&
  width 100%
  min-height 100%
  display flex
  flex-direction column

  [data-show="false"], .hidden
    display none

  .store_register_button
    width 9rem
    bottom 0
    right 0
    position absolute

  .header
    flex-shrink 0
    width 100%
    margin-top 30px
    .title
      display flex
      align-items center
      justify-content space-between
      h2
        height 4rem
        line-height 4rem
        margin 0
    @import "../filter.styl"

  .chart-wrapper
    min-width 1400px
    .logo
      width 5rem !important
    .store_label,
    .store_bound,
    .store_category
      width 8%
    .store_manager
      width 8%
    .seller
      width 15%
      word-break break-word
    .products_count
      width 14%
    .description, .review
      width 15%
      word-break break-all

    tbody
      tr
        height 4rem
      .logo
        text-align center
        padding 0
        .product_info
          display inline-flex
      .seller .user .user_email
        margin-left 0.4rem
      .link
        @import "../links.styl"
      .store_label,
      .store_bound,
      .store_category
        @import "../store_label.styl"
      .store_manager_td
        @import "../store_manager.styl"
      .seller-filter
        position relative
        &__check
          position absolute
          display none
          left 90%
          top -10%
          padding 10px
          background #fff
          width 200px
          border 1px solid #aaa
        &:hover
          .seller-filter__check
            display block


&__header
  display flex
  justify-content space-between
  align-items center
.active-stores-data
  border 1px solid rgba(24, 28, 33, 0.06)
  margin-bottom 20px
  &__section
    display flex
    justify-content space-between
    align-items center
    gap 30px
    padding 4px
    border 1px solid rgba(24, 28, 33, 0.06)
    &:nth-child(2n)
      border none
      background-color rgba(24,28,33,0.025)
      background-color rgba(24,28,33,0.025)
  &__title
    width 100px
    border-right 1px solid rgba(24, 28, 33, 0.06)
    padding 4px 10px
  &__content
    padding 4px
