&
  position relative
  width 100%
  padding 0 10px
  &__list
    input
    select
    button
      border-radius 2px
      border 1px solid #ccc
      height 38px
      margin-right 4px
      padding 0 10px
      background-color transparent
      &[disabled]
        background-color #f0f0f0

  &__title
    margin 0 0 20px
  &__description
    margin-bottom 10px
  .crew-inspect-search
    &__search
      position relative
      margin-bottom 20px
    &__option
      display flex
      align-items center
      margin-bottom 10px
      &--center
        justify-content center
    &__title
      width 100px
      font-weight bold
  .crew-inspect-body
    &__excel
      display flex
      display none
      justify-content flex-end
  .chart-wrapper
    margin-top 40px
  .crew-inspect-table
    &__head > tr > th
      vertical-align middle
      min-width 50px
  .crew-inspect-product-item
    cursor pointer
    &__pass
    &__nopass
      cursor pointer
      position relative
      text-decoration underline
      .don_loader_wrap
        position absolute


